import React from 'react';
import clsx from 'clsx';

import { assignInlineVars } from '@vanilla-extract/dynamic';
import { Slot } from '@radix-ui/react-slot';
import { createDynamicVars } from '../../global/utils';
import { AsChild, InternalComponentProps } from '../Slot';
import { ResponsiveValue } from '../../global/breakpoints';
import * as styles from './GridItem.css';

type Columns =
  | '1'
  | '2'
  | '3'
  | '4'
  | '5'
  | '6'
  | '7'
  | '8'
  | '9'
  | '10'
  | '11'
  | '12';

type GridColumn = Columns | `-${Columns}` | 'auto' | `span ${Columns}`;

type GridItemProps = {
  children: React.ReactNode;
  columnStart?: ResponsiveValue<GridColumn>;
  columnEnd?: ResponsiveValue<GridColumn>;
  rowStart?: ResponsiveValue<string>;
  rowEnd?: ResponsiveValue<string>;
  gridArea?: ResponsiveValue<string>;
};

type GridItemComponentProps = GridItemProps & AsChild & InternalComponentProps;

export const GridItem = React.forwardRef<
  HTMLDivElement,
  GridItemComponentProps
>(
  (
    {
      children,
      columnEnd = '-1',
      columnStart = '1',
      rowStart,
      rowEnd,
      gridArea,
      className = '',
      as = 'div',
      asChild,
      style = {},
    },
    ref
  ) => {
    const dynamicVars = createDynamicVars([
      {
        value: rowStart,
        vars: Object.values(styles.gridRowStartVars.extractedVars),
      },
      {
        value: rowEnd,
        vars: Object.values(styles.gridRowEndVars.extractedVars),
      },
      {
        value: gridArea,
        vars: Object.values(styles.gridAreaVars.extractedVars),
      },
      {
        value: columnStart,
        vars: Object.values(styles.gridColumnStartVars.extractedVars),
      },
      {
        value: columnEnd,
        vars: Object.values(styles.gridColumnEndVars.extractedVars),
      },
    ]);

    const Component = asChild ? Slot : as;

    return (
      <Component
        className={clsx(
          styles.root,
          {
            [styles.gridRowStart]: !!rowStart,
            [styles.gridRowEnd]: !!rowEnd,
            [styles.gridColumnStart]: !!columnStart,
            [styles.gridColumnEnd]: !!columnEnd,
          },
          className
        )}
        style={{ ...assignInlineVars(dynamicVars), ...style }}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
