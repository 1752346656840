import React from 'react';
import clsx from 'clsx';
import { Slot } from '@radix-ui/react-slot';
import { isElement } from 'react-is';
import { createDynamicVars } from '../../global/utils';
import { AsChild, InternalComponentProps } from '../Slot';
import { ResponsiveValue } from '../../global/breakpoints';
import * as boxStyles from './Box.css';

export type BoxProps = {
  children?: React.ReactNode;
  width?: ResponsiveValue<`${number}px` | `${number}%` | `${number}vw`>;
  height?: ResponsiveValue<`${number}px` | `${number}%` | `${number}vh`>;
} & boxStyles.Variants;

type BoxComponentProps = BoxProps & AsChild & InternalComponentProps;

export const Box = React.forwardRef<HTMLDivElement, BoxComponentProps>(
  (props, ref) => {
    const {
      children,
      width,
      height,
      className = '',
      style = {},
      as = 'div',
      asChild,
      ...recipeProps
    } = props;

    const dynamicVariants = createDynamicVars([
      { value: width, vars: Object.values(boxStyles.widthVars.extractedVars) },
      {
        value: height,
        vars: Object.values(boxStyles.heightVars.extractedVars)
      },
    ]);

    const recipeClassnames = boxStyles.box(recipeProps);
    const Component = asChild && isElement(children) ? Slot : as;

    return (
      <Component
        className={clsx(
          { [boxStyles.width]: !!width, [boxStyles.height]: !!height },
          recipeClassnames,
          className,
          boxStyles.root
        )}
        style={{ ...dynamicVariants, ...style }}
        ref={ref}
      >
        {children}
      </Component>
    );
  }
);
