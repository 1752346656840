import { Headers } from '../components/Heading';
import { ResponsiveValue } from './breakpoints';

/**
 * These are utilites that are used in runtime
 * Vanilla Extract buildtime utilities are in `utils.css.ts`.
 */

type DynamicVars = {
  value?: ResponsiveValue<string | number | boolean>;
  vars: string[];
}[];

export function createDynamicVars(props: DynamicVars) {
  return props.reduce((acc, { value, vars }) => {
    if (typeof value === 'string') {
      return {
        ...acc,
        [vars[0]]: value,
      };
    }

    if (typeof value === 'object') {
      // TODO: Only map breakpoints that are defined in the value
      // TODO: Automate this
      return {
        ...acc,
        [vars[0]]: value.initial,
        [vars[1]]: value.sm,
        [vars[2]]: value.md,
        [vars[3]]: value.lg,
        [vars[4]]: value.xl,
      };
    }

    return acc;
  }, {});
}

/**
 *
 * @param v A string in the format of `var(--varName)`
 * @returns The varName
 */
export function extractValueFromVar(v: string) {
  return v.replace(/^var\(|\)$/g, '');
}

export const postalCodePattern = {
  value: /^[0-9]{4}\s?[A-Za-z]{2}$/i,
  message: 'Vul een geldige postcode in: 1234AB',
};

export const telephonePattern = {
  value: /^\+\d{1,4} (?!0)\d{7,14}$/i,
  message: 'Vul een geldig telefoonnummer inclusief landcode in: +31 612345678'
}

export const houseNumberPattern = {
  value: /^[0-9]*$/i,
  message: 'Vul een geldig huisnummer in.'
}

export const scrollToElement = (
  ref: React.RefObject<HTMLElement>,
  offset?: number
) => {
  if (ref.current) {
    const offsetTop = offset ? offset : 0;
    const y =
      ref.current.getBoundingClientRect().top + window.scrollY + -offsetTop;

    window.scrollTo({ top: y, behavior: 'smooth' });
  }
};

/** Type guards */
export function isHeadingSize(size: string): size is Headers {
  return ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'].includes(size);
}
